<template>
  <div
    :class="[
      'flex items-center justify-center rounded-full bg-gray-100 px-3 py-1.5',
      props.css,
    ]"
  >
    <span :class="['text-xs font-medium text-gray-600', props.textCss]"
      ><slot></slot
    ></span>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  css?: string;
  textCss?: string;
}>();
</script>
