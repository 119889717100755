import { computed, ref, watchEffect } from "vue";
import HttpClient from "@/utils/httpClient";
import { Settings } from "luxon";
import router from "@/router";
import dictionary from "@/dictionary";

export type Culture = "nl-NL" | "en-US" | "fr-FR" | "de-DE";

const culture = ref<Culture>("en-US");
export default culture;

watchEffect(
  async () => {
    HttpClient.setCulture(culture.value);
    Settings.defaultLocale = culture.value;
    await router.isReady();
    router.replace({
      path: router.currentRoute.value.path,
      query: { ...router.currentRoute.value.query, culture: culture.value },
    });
  },
  {
    flush: "post",
  },
);

export const texts = computed(() => dictionary[culture.value]);
