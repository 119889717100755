// region: Lazy Loaded View Components
const ActivityList = () => import("@/views/ActivityList.vue");

import {
  createRouter,
  createWebHistory,
  RouteLocation,
  RouteRecordRaw,
  RouterView,
} from "vue-router";

import NotFound from "@/views/NotFound.vue";

import { RouteNames } from "./routeNames";

import env from "@/env";
import culture, { Culture } from "@/utils/culture";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import HttpClient from "@/utils/httpClient";
import { ApiException } from "lib/eduConfigurationServiceClient";
import { colorTenant } from "@/utils/color";

export const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    components: {
      default: RouterView,
    },
    children: [
      {
        path: "activities",
        component: RouterView,
        children: [
          {
            path: ":tenant",
            beforeEnter: async (to, from, next) => {
              const queryCulture = to.query.culture as Culture | undefined;
              const uri = to.params["tenant"] as string;

              HttpClient.setTenant(uri);
              const languages =
                await eduConfigurationServiceClient.getPublicLanguages();

              if (!queryCulture) {
                const defaultCulture =
                  (languages.mainLanguage.locale.value as Culture) ?? "en-US";
                return next({
                  ...to,
                  query: { ...to.query, culture: defaultCulture },
                });
              }

              const settings = await eduConfigurationServiceClient
                .getPublicActivityPageSettings()
                .then((settings) => {
                  const queryType = to.query.activityTypeId as
                    | string
                    | undefined;
                  if (!queryType) {
                    return settings.find((i) => !i.activityTypeId);
                  }
                  return (
                    settings.find((i) => i.activityTypeId === queryType) ??
                    settings.find((i) => !i.activityTypeId)
                  );
                })
                .catch((err) => {
                  if (err instanceof ApiException && err.status === 404) {
                    return undefined;
                  }
                  throw err;
                });

              culture.value = queryCulture;
              to.meta.tenant = { uri, languages, settings };
              colorTenant.value = settings?.color;

              next();
            },
            component: ActivityList,
            props: (route: RouteLocation) => ({
              activityTypeId: route.query.activityTypeId,
              studyProgramId: route.query.studyProgramId,
              tenant: route.meta.tenant,
              settings: route.meta.settings,
            }),
          },
        ],
      },
    ],
  },
  {
    path: "/:pathMatch(.*)",
    name: RouteNames.NOT_FOUND,
    component: NotFound,
    meta: {
      title: "not-found",
      doesNotRequireTenant: true,
      doesNotRequireAuthentication: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(env.BASE_URL),
  routes,
});

router.beforeEach((to, _from, next) => {
  const title = to.meta?.title as string | undefined;
  document.title = title ? `${title}` : "Activiteiten";
  next();
});

export default router;
