import { Culture } from "@/utils/culture";
import * as NL from "@/dictionary.nl-NL.json";
import * as EN from "@/dictionary.en-US.json";
import * as FR from "@/dictionary.fr-FR.json";
import * as DE from "@/dictionary.de-DE.json";

export type Localizable<T> = Record<Culture, T>;

export type ApplicationDictionary = typeof NL;

const dictionary: Localizable<ApplicationDictionary> = {
  ["nl-NL"]: NL,
  ["en-US"]: EN,
  ["fr-FR"]: FR,
  ["de-DE"]: DE,
};

export default dictionary;
