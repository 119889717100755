<template>
  <input
    v-model="checked"
    type="checkbox"
    class="h-4 w-4 rounded border-gray-300 text-tenant focus:ring-tenant"
    :indeterminate="indeterminate"
  />
</template>

<script setup lang="ts">
import { computed } from "vue";
import { FilterOption } from "@/views/components/activity-filter/ActivityFilter.vue";

const checked = defineModel<boolean | null | string[] | FilterOption[]>({
  set(value) {
    if (checked.value == null) {
      return false;
    } else {
      return value ?? null;
    }
  },
});

const indeterminate = computed(() => checked.value === null);
</script>
