import { App } from "vue";

import Badge from "@/elements/badge/Badge.vue";
import Button from "@/elements/button/Button.vue";
import Checkbox from "@/elements/checkbox/Checkbox.vue";
import Col from "@/elements/col/Col.vue";
import Divider from "@/elements/divider/Divider.vue";
import Heading1 from "@/elements/heading/Heading1.vue";
import Heading2 from "@/elements/heading/Heading2.vue";
import Heading3 from "@/elements/heading/Heading3.vue";
import Heading4 from "@/elements/heading/Heading4.vue";
import Icon from "@/elements/icon/Icon.vue";
import Input from "@/elements/input/Input.vue";
import Select from "@/elements/input/Select.vue";
import Link from "@/elements/link/Link.vue";
import Paragraph from "@/elements/paragraph/Paragraph.vue";
import Row from "@/elements/row/Row.vue";

declare module "@vue/runtime-core" {
  export interface GlobalComponents {
    Badge: typeof Badge;
    Button: typeof Button;
    Checkbox: typeof Checkbox;
    Divider: typeof Divider;
    Col: typeof Col;
    Heading1: typeof Heading1;
    Heading2: typeof Heading2;
    Heading3: typeof Heading3;
    Heading4: typeof Heading4;
    Icon: typeof Icon;
    Input: typeof Input;
    Select: typeof Select;
    Link: typeof Link;
    Paragraph: typeof Paragraph;
    Row: typeof Row;
  }
}

export default function registerElements(app: App) {
  app.component("Badge", Badge);
  app.component("Button", Button);
  app.component("Checkbox", Checkbox);
  app.component("Divider", Divider);
  app.component("Col", Col);
  app.component("Heading1", Heading1);
  app.component("Heading2", Heading2);
  app.component("Heading3", Heading3);
  app.component("Heading4", Heading4);
  app.component("Icon", Icon);
  app.component("Input", Input);
  app.component("Select", Select);
  app.component("Link", Link);
  app.component("Paragraph", Paragraph);
  app.component("Row", Row);
}
