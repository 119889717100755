<template>
  <RouterView />
</template>

<script setup lang="ts">
import { watch } from "vue";
import { colorTenant, toRGB } from "@/utils/color";

watch(
  colorTenant,
  () => {
    if (!colorTenant.value) return undefined;

    document.body.style.setProperty("--color-tenant", toRGB(colorTenant.value));
  },
  { immediate: true },
);
</script>
